// Dependencies
import { useState, useEffect } from 'react';
import '../../styles/lander/content_search.css';

// Helper Components
function LoadingCircle() {
    return (
        <div className="loading-circle">
            <div className="loading-circle-inner"></div>
        </div>
    );
}

function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;
    
    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === 'Escape') onClose();
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);
    
    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>×</button>
                {children}
            </div>
        </div>
    );
}

function SearchResults({ data, status, error }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    
    if (status === 'loading') {
        return (
            <div className="search-results-loading">
                <LoadingCircle />
                <p>Analyzing link content...</p>
            </div>
        );
    }
    
    if (status === 'error-400') {
        return (
            <div className="search-results-error">
                <br/>
                <h3>Please try again.</h3>
                <p>Something went wrong. Please ensure you are using a valid link, and try again in a few minutes.</p>
            </div>
        );
    }
    
    if (status === 'error-500') {
        return (
            <div className="search-results-error">
                <h3>Oops!</h3>
                <p>Looks like we can't access that link. We may still be able to help you out - get in touch with us to discuss your specific needs.</p>
                <a href="/contact-us" className="contact-us-button">Contact Us</a>
            </div>
        );
    }
    
    if (status === 'success' && data) {
        return (
            <div className="search-results-success">
                {data.image && (
                    <div className="preview-image-container">
                        {!imageLoaded && <div className="image-placeholder"></div>}
                        <img 
                            src={data.image} 
                            alt="Preview" 
                            className="preview-image" 
                            style={{ display: imageLoaded ? 'flex' : 'none' }}
                            onLoad={() => setImageLoaded(true)}
                            onError={() => setImageLoaded(true)}
                        />
                    </div>
                )}
                
                <div className="result-item">
                    <h3>Title</h3>
                    <p>{data.title || 'No title found'}</p>
                </div>
                
                <div className="result-item">
                    <h3>Description</h3>
                    <p>{data.description || 'No description found'}</p>
                </div>
                
                <div className="result-item">
                    <h3>Link</h3>
                    <p>{data.url || 'No link found'}</p>
                </div>
                
                <div style={{ textAlign: 'center', marginTop: '32px' }}>
                    <a href="/get-started" className="contact-us-button">Get Started</a>
                </div>
            </div>
        );
    }
    
    return null;
}

// Functional Component
export default function TryContentSearch() {
    // States
    const [link, setLink] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    
    // Wrappers
    const [searchData, setSearchData] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    
    // For canceling fetch requests
    const [controller, setController] = useState(null);
    
    const handleClose = () => {
        if (controller) {
            controller.abort(); // Cancel the fetch request
        }
        setSearchOpen(false);
        setSearchLoading(false);
        setSearchStatus(null);
    };
    
    const handleSearch = async () => {
        if (searchLoading || link.length <= 0) return;
        
        setSearchOpen(true);
        setSearchLoading(true);
        setSearchStatus('loading');
        setSearchData(null);
        
        // Create a new AbortController for this request
        const newController = new AbortController();
        setController(newController);
        
        try {
            // Get content
            const response = await fetch('/api/trylink', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ link }),
                signal: newController.signal
            });
            
            if (response.status === 400) {
                setSearchStatus('error-400');
            } else if (response.status === 500) {
                setSearchStatus('error-500');
            } else if (response.ok) {
                const data = await response.json();
                setSearchData(data);
                setSearchStatus('success');
            } else {
                setSearchStatus('error-500');
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                setSearchStatus('error-500');
            }
        } finally {
            setSearchLoading(false);
        }
    }

    // Return layout
    return (
        <>
            <Modal isOpen={searchOpen} onClose={handleClose}>
                <SearchResults data={searchData} status={searchStatus} />
            </Modal>
            <>
                <input type="text" placeholder="Paste a link here..." value={link} onChange={(e)=>setLink(e.target.value || "")} />
                <button onClick={handleSearch}>
                    {searchLoading ? <LoadingCircle /> : <>Get Started</>}
                </button>
            </>
        </>
    );
} 